// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --background-color: #f8f9fa;
    --primary-color: #6a0dad; /* Dark Purple */
    --primary-light: #d1b3ff; /* Light Purple */
    --text-color: #333333;
    --font-family: 'Roboto', sans-serif;
  }
  
  body {
    background-color: var(--background-color);
    font-family: var(--font-family);
    margin: 0;
    padding: 0;
    line-height: 1.6;
  }
  
  .error {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .main-content {
    padding: 60px 20px;
    max-width: 1200px;
    margin: 0 auto;
    flex: 1 1;
    width: 90%;
  }
  
  h1 {
    font-size: 2.5rem;
    color: var(--primary-color);
    margin-bottom: 20px;
  }
  
  p {
    font-size: 1.2rem;
    color: var(--text-color);
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/Error.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,wBAAwB,EAAE,gBAAgB;IAC1C,wBAAwB,EAAE,iBAAiB;IAC3C,qBAAqB;IACrB,mCAAmC;EACrC;;EAEA;IACE,yCAAyC;IACzC,+BAA+B;IAC/B,SAAS;IACT,UAAU;IACV,gBAAgB;EAClB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,iBAAiB;EACnB;;EAEA;IACE,kBAAkB;IAClB,iBAAiB;IACjB,cAAc;IACd,SAAO;IACP,UAAU;EACZ;;EAEA;IACE,iBAAiB;IACjB,2BAA2B;IAC3B,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;IACjB,wBAAwB;EAC1B","sourcesContent":[":root {\n    --background-color: #f8f9fa;\n    --primary-color: #6a0dad; /* Dark Purple */\n    --primary-light: #d1b3ff; /* Light Purple */\n    --text-color: #333333;\n    --font-family: 'Roboto', sans-serif;\n  }\n  \n  body {\n    background-color: var(--background-color);\n    font-family: var(--font-family);\n    margin: 0;\n    padding: 0;\n    line-height: 1.6;\n  }\n  \n  .error {\n    display: flex;\n    flex-direction: column;\n    min-height: 100vh;\n  }\n  \n  .main-content {\n    padding: 60px 20px;\n    max-width: 1200px;\n    margin: 0 auto;\n    flex: 1;\n    width: 90%;\n  }\n  \n  h1 {\n    font-size: 2.5rem;\n    color: var(--primary-color);\n    margin-bottom: 20px;\n  }\n  \n  p {\n    font-size: 1.2rem;\n    color: var(--text-color);\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
