import React, { useState, useEffect, useCallback } from 'react';
import { motion } from 'framer-motion';
import Header from '../components/Header';
import Footer from '../components/Footer';
import './Home.css';
import cvAnalysisPic from '../assets/CVAnalysispic.jpg';

function Home() {
  const [scrollY, setScrollY] = useState(0);
  const [activeSection, setActiveSection] = useState('hero');

  const handleScroll = useCallback(() => {
    setScrollY(window.scrollY);
    const sections = ['hero', 'cv-analysis'];
    const currentSection = sections.find(section => {
      const element = document.getElementById(section);
      if (element) {
        const rect = element.getBoundingClientRect();
        return rect.top <= 100 && rect.bottom >= 100;
      }
      return false;
    });
    if (currentSection) setActiveSection(currentSection);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  return (
    <div className="home">
      <Header activeSection={activeSection} />
      <main className="main-content">
        <HeroSection scrollY={scrollY} />
        <CVAnalysisSection />
      </main>
      <Footer />
    </div>
  );
}

const HeroSection = ({ scrollY }) => (
  <motion.section 
    id="hero"
    className="hero" 
    style={{ backgroundPositionY: scrollY * 0.5 }}
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 0.5 }}
  >
    <div className="hero-content">
      <div className="hero-text">
        <motion.h1 
          className="title"
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.5 }}
        >
          <span className="purple">Find</span> Your Perfect Job Match<br/>
          <span className="purple">Tailored</span> Just for You
        </motion.h1>
        <motion.p 
          className="subtitle"
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.4, duration: 0.5 }}
        >
          Credolay uses expert insights and AI to match you with opportunities that align with your unique skills and career goals.
        </motion.p>
      </div>
      <div className="cta-container">
        <motion.a 
          href="/analyze-cv" 
          className="cta-button"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.6, duration: 0.5 }}
        >
          Try Our CV Analysis
        </motion.a>
      </div>
    </div>
  </motion.section>
);

const CVAnalysisSection = () => (
  <section id="cv-analysis" className="cv-analysis-section">

    <div className="cv-analysis-content">
      <motion.img 
        src={cvAnalysisPic} 
        alt="CV Analysis" 
        className="cv-analysis-image"
        whileHover={{ scale: 1.05 }}
        transition={{ duration: 0.3 }}
      />
      <div className="cv-analysis-text">
        <h4>Why Choose Our CV Analysis?</h4>
        <ul>
          <li>In-depth assessment of your skills and experience</li>
          <li>Personalized recommendations for improvement</li>
          <li>Industry-specific insights and keyword optimization</li>
          <li>ATS (Applicant Tracking System) compatibility check</li>
          <li>Tailored job matches based on your CV content</li>
        </ul>
        <p>
          For just £4 per month, you'll receive ongoing CV analysis and job matching services, keeping your professional profile up-to-date and aligned with the latest industry trends.
        </p>
        <motion.a 
          href="#subscription-signup" 
          className="button"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          Start Your 7-Day Free Trial
        </motion.a>
      </div>
    </div>
  </section>
);


export default Home;
